export const ExperimentsConsts = {
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  Cart: 'specs.bookings.cart',
  HideCouponInFormPage: 'specs.bookings.HideCouponInFormPage',
  SendAddressToEcomCheckoutAndCart:
    'specs.bookings.sendAddressToEcomCheckoutAndCart',
  ChangePricingPlanNavigationToNewApi:
    'specs.bookings.ChangePricingPlanNavigationToNewApi',
  BookingsFormWidgetCssOptimization:
    'specs.bookings.BookingsFormWidgetCssOptimization',
  EditorOOIFormPage: 'specs.bookings.EditorOOIFormPage',
  MultiServiceAppointment: 'specs.bookings.multiServiceAppointment',
  FormPaymentOptionNewDropdown: 'specs.bookings.FormPaymentOptionNewDropdown',
  AllowEditCartPopup: 'specs.bookings.AllowEditCartPopup',
  AllowEditPolicySectionTitle: 'specs.bookings.AllowEditPolicySectionTitle',
  BookOnBehalf: 'specs.bookings.BookOnBehalf',
  CartModalModifications: 'specs.bookings.CartModalModifications',
  BenfitsApiResiliance: 'specs.bookings.BenfitsApiResiliance',
  ShouldShowCountryCodeWithFlags:
    'specs.bookings.ShouldShowCountryCodeWithFlags',
  ShowDurationInDays: 'specs.bookings.ShowDurationInDays',
};
